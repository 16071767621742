




































import { VueConstructor } from 'vue'
import { defineComponent, ref } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'
/// <reference path="@/modules/vue-slide-up-down.d.ts" />
import SlideUpDown from 'vue-slide-up-down'

export default defineComponent({
  name: 'CollapsePanel',
  components: {
    Loading,
    SlideUpDown: (SlideUpDown as unknown) as VueConstructor,
  },
  props: {
    title: { type: String, required: false, default: '' },
    initialCollapse: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const collapsed = ref(props.initialCollapse)

    return {
      collapsed,
    }
  },
})
