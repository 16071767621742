































































































import Alert from '@/components/Alert.vue'
import Modal from '@/components/Modal.vue'
import { Component, Vue, Prop } from 'vue-property-decorator'
import sanitizeHtml from 'sanitize-html'
import { dateFmt } from '@/filters/dateFmt.ts'
import { MessageData, getEmptyMessageData } from '@/lib/support/models/Communication/PreviewMessage.ts'
import Loading from '@/elements/Loading.vue'

@Component({
  components: {
    Modal,
    Loading,
    Alert,
  },
})
export default class CommunicationModal extends Vue {
  @Prop({ type: Boolean, required: true })
  private readonly value!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  private readonly isPreview!: boolean

  @Prop({ type: Boolean, default: false, required: false })
  private readonly sendingEmailInProgress!: boolean

  @Prop({ type: Object, default: getEmptyMessageData, required: false })
  private readonly message!: MessageData

  @Prop({ type: String, required: true })
  private readonly title!: string

  private get show() {
    return this.value
  }

  private set show(val: boolean) {
    this.$emit('input', val)
  }

  copyAddresses = false
  private wasCopied = false

  private copyAddress() {
    this.copyAddresses = true
    document.execCommand('copy')
    this.copyAddresses = false
    this.wasCopied = true
  }

  copyingAddress = false
  private copyEmailAddress(e: any) {
    if (!this.copyAddresses || this.copyingAddress) {
      return
    }
    this.copyingAddress = true

    var csvRecipients = this.message.individualRecipients.reduce((s, a) => `${s},${a}`)
    e.clipboardData.setData('text/plain', csvRecipients)

    // This is necessary to prevent the current document selection from
    // being written to the clipboard.
    e.preventDefault()
    this.copyingAddress = false
  }

  mounted() {
    document.addEventListener('copy', this.copyEmailAddress)
  }

  private get sanitizedMessage() {
    const msg = this.message.messageBody ? this.message.messageBody : ''
    return sanitizeHtml(msg, { allowedTags: sanitizeHtml.defaults.allowedTags.concat('u') })
  }

  private get sendingResults() {
    const results = this.message.thirdPartyResponse ? this.message.thirdPartyResponse : ''

    if (!results.includes('errors')) return 'Message Sent Successfully.'

    return results
  }

  private send() {
    this.$emit('sendMessage')
  }
  private get summaryMessage() {
    if (!this.message) return ''

    let dateMsg = ''
    let verbTense = 'will send'
    const plural = this.message.recipientCount === 1 ? '' : 's'

    if (!this.isPreview) {
      verbTense = 'sent'
      const dt = dateFmt(this.message.sentDate, 'MM/dd/yy hh:MMa')
      dateMsg = ` on <span class="font-weight-bold">${dt}</span>`
    }

    return ` You ${verbTense} the following <span class="font-weight-bold">${this.message.messageType}
    </span> to <span class="font-weight-bold">${this.message.recipientCount} </span>recipient${plural}${dateMsg}.`
  }
}
