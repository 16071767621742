






import { defineComponent, PropType } from '@vue/composition-api'
import { MessageItem } from '@/views/Programs/Communications/model/MessageItem'
import Loading from '@/elements/Loading.vue'
export default defineComponent({
  name: 'CommsSearchButton',
  props: {
    item: { type: Object as PropType<MessageItem>, required: true },
    loadingItemId: { type: Number, required: true },
  },
  components: { Loading },
  setup(props, { emit }) {
    function click(item: MessageItem) {
      emit('click', item)
    }
    return { click }
  },
})
