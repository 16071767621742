










































































import { Component, Vue, Watch } from 'vue-property-decorator'
import dayjs from 'dayjs'
import { Getter } from 'vuex-class'
import ErrorIcon from '@/views/Programs/Communications/vue/ErrorIcon.vue'
import SearchButton from '@/views/Programs/Communications/vue/SearchButton.vue'
import Loading from '@/elements/Loading.vue'
import CollapsePanel from '@/components/CollapsePanel.vue'
import TextInput from '@/elements/TextInput.vue'
import CommunicationModal from '@/components/CommunicationModal.vue'
import DataTable from '@/elements/DataTable/DataTable.vue'
import { DataTableHeader } from 'vuetify/types'
import communicationsClient from '@/clients/communicationsClient'
import { EmailSMSLeagueMessageInfo } from '@/GeneratedTypes/Communication/EmailSMSLeagueMessage'
import { EmailSMSAccount } from '@/GeneratedTypes/Communication/EmailSMSAccount'
import { MessageItem } from '@/views/Programs/Communications/model/MessageItem'
import { maxStringLength } from '@/filters/maxStringLength'
import * as communicationsStore from '@/store/communications'
import {
  toMessageDataFromEmailSMSLeagueMessageInfo,
  getEmptyMessageData,
} from '@/lib/support/models/Communication/PreviewMessage.ts'

@Component({
  components: {
    Loading,
    CollapsePanel,
    TextInput,
    CommunicationModal,
    DataTable,
    ErrorIcon,
    SearchButton,
  },
})
export default class History extends Vue {
  @Getter(communicationsStore.getterNames.currentItem, { namespace: communicationsStore.namespace })
  private readonly providerInfo!: EmailSMSAccount

  private loading = false
  private loadingMessage = 0
  private showModal = false

  private selectedMessage = getEmptyMessageData()
  private emailFilter = ''
  private emailHistory: EmailSMSLeagueMessageInfo[] | null = []
  private smsFilter = ''
  private smsHistory: EmailSMSLeagueMessageInfo[] | null = []

  @Watch('providerInfo')
  private async onInfoChange() {
    await this.populateMessageData()
  }

  public async mounted() {
    await this.populateMessageData()
  }

  get emailHeaders(): DataTableHeader[] {
    let headers: DataTableHeader[] = [
      { text: 'From', value: 'emailReplyTo' },
      { text: 'Subject', value: 'emailSubject' },
      {
        text: 'Attachments',
        value: 'attachmentsCount',
        align: 'center',
      },
      { text: 'Date Sent', value: 'sent' },
      { text: '', value: 'actions' },
    ]
    if (this.emails.some((e) => e.hasError)) {
      headers.unshift({ text: '', value: 'hasError' })
    }
    return headers
  }

  get smsHeaders(): DataTableHeader[] {
    let headers = [
      { text: 'Recipients', value: 'recipientsString' },
      { text: 'Date Sent', value: 'sent' },
      { text: '', value: 'actions' },
    ]
    if (this.smsList.some((e) => e.hasError)) {
      headers.unshift({ text: '', value: 'hasError' })
    }
    return headers
  }

  get emails() {
    let email = this.emailHistory
      ? this.emailHistory
          ?.filter((m) => {
            let isSent = false
            if (!m.sendCommunicationOn) isSent = true
            else if (m.sendCommunicationOn && m.sentComplete === true) isSent = true
            return isSent
          })
          .map(this.msgMap)
          .sort(this.dateSort)
      : []
    return this.emailFilter ? email.filter(this.emailFilterFnc) : email
  }

  get smsList() {
    let sms = this.smsHistory
      ? this.smsHistory
          ?.filter((m) => {
            let isSent = false
            if (!m.sendCommunicationOn) isSent = true
            else if (m.sendCommunicationOn && m.sentComplete === true) isSent = true
            return isSent
          })
          .map(this.msgMap)
          .sort(this.dateSort)
      : []
    return this.smsFilter ? sms.filter(this.smsFilterFnc) : sms
  }

  msgMap(msg: EmailSMSLeagueMessageInfo) {
    const sentDate = msg.sendCommunicationOn ? msg.sendCommunicationOn : msg.createDate
    return {
      ...msg,
      recipientsString: msg.recipients ? maxStringLength(msg.recipients.join(', '), 30) : '',
      recipientsCount: msg.recipients?.length ?? 0,
      sent: dayjs(sentDate ?? '').format('MM/DD/YY hh:mma'),
      hasError: msg.thirdPartyResponse?.includes('errors') ? true : false,
    } as MessageItem
  }

  dateSort(a: MessageItem, b: MessageItem) {
    const aSentDate = a.sendCommunicationOn ? a.sendCommunicationOn : a.createDate
    const bSentDate = b.sendCommunicationOn ? b.sendCommunicationOn : b.createDate
    return dayjs(aSentDate ?? '').isBefore(dayjs(bSentDate ?? '')) ? 1 : -1
  }

  smsFilterFnc(msg: MessageItem) {
    return msg.recipientsString.toLowerCase().search(this.smsFilter.toLowerCase()) != -1
  }

  emailFilterFnc(msg: MessageItem) {
    return (
      `${msg.recipientsString}${msg.emailSubject}`.toLowerCase().search(this.emailFilter.toLowerCase()) != -1
    )
  }

  private async populateMessageData() {
    try {
      this.loading = true
      const emailSmsAccountID = this.providerInfo.emailSmsAccountID

      const upwID = this.$route.params.id

      const leagueSetting = await communicationsClient.retrieveLeagueSettings(upwID, emailSmsAccountID)

      if (leagueSetting) {
        this.emailHistory = await communicationsClient.retrieveMessages(
          upwID,
          emailSmsAccountID,
          communicationsClient.MessageTypes.Email
        )
        this.smsHistory = await communicationsClient.retrieveMessages(
          upwID,
          emailSmsAccountID,
          communicationsClient.MessageTypes.Sms
        )
      }
    } finally {
      this.loading = false
    }
  }

  public async showMessage(message: MessageItem) {
    const currentMessage = message
    try {
      const mt =
        currentMessage.messageType === 'Email'
          ? communicationsClient.MessageTypes.Email
          : communicationsClient.MessageTypes.Sms

      this.loadingMessage = currentMessage.emailSmsMessageID
      const message = await communicationsClient.retrieveMessage(currentMessage.emailSmsMessageID, mt)
      this.loadingMessage = 0

      this.selectedMessage = toMessageDataFromEmailSMSLeagueMessageInfo(message)
      this.showModal = true
    } finally {
    }
  }
}
