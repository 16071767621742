// In preparation for Vue3, this function can be used in lieu of date filter
import { format } from 'date-fns'

export function dateFmt(value: Date | string | null | undefined, formatTemplate = 'MM/dd/yy') {
  if (!value) {
    return ''
  }

  return format(new Date(value), formatTemplate)
}
