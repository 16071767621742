





import { defineComponent, PropType } from '@vue/composition-api'
import { MessageItem } from '@/views/Programs/Communications/model/MessageItem'
export default defineComponent({
  name: 'CommsErrorIcon',
  props: { item: { type: Object as PropType<MessageItem>, required: true } },
  components: {},
  setup() {
    return {}
  },
})
